import utils from "./../../globals/layout/scripts/utils.js";

document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("registration-submit-top").addEventListener("click", utils.sendToOnBoarding);

  document.getElementById("email-input").addEventListener("keypress", function(e) {
    var key = e.which || e.keyCode;

    if (key === 13) { // 13 is enter
      document.getElementById("registration-submit-top").click();
    }
  });
});
