import $ from "jquery";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import axios from "axios";

const utils = {
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if mail is valid, test for local domains
    if (re.test(email)) {
      var domain = email.replace(/.*@/, "");
      var sub_level = domain.split(".")[0];
      var top_level = domain.split(".")[1];

      if (sub_level === "localhost" || sub_level === "local" || sub_level === "localdomain" || top_level === "localhost" || top_level === "local" || top_level === "localdomain") {
        return false;
      }
      return true;

    }
  },

  b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    }));
  },

  sendToOnBoarding(event) {
    event.preventDefault();

    let inputId = "";
    const prevContent = this.innerHTML;
    const submitBtn = this;

    submitBtn.innerHTML = "<i class=\"fas fa-spin fa-sync-alt\"></i> Thinking";

    if (this.id === "registration-submit-top") {
      inputId = "email-input";
    } else {
      inputId = "email-input-2";
    }

    $("#" + inputId).tooltip("dispose");

    const email = document.getElementById(inputId).value;

    if (email.length === 0) {
      submitBtn.innerHTML = prevContent;
      document.getElementById(inputId).focus();
    } else if (utils.validateEmail(email)) {
      axios({
        method: "GET",
        url: document.getElementById(this.id).href + "?user_email=" + email,
        data: "",
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(function(response) {
        if (response.data.exists) {
          submitBtn.innerHTML = prevContent;
          $("#" + inputId).attr("title", "Email already exists!").tooltip("show");
        } else {
          window.location.href = response.data.redirect + "?m=" + utils.b64EncodeUnicode(email);
        }
      }).catch(function() {
        submitBtn.innerHTML = prevContent;
      });
    } else {
      submitBtn.innerHTML = prevContent;
      $("#" + inputId).attr("title", "Invalid email!").tooltip("show");
    }

    return false;
  },

  animateValue(elemId, start, end, duration) {
    // assumes integer values for start and end
    var obj = document.querySelector("." + elemId) || document.getElementById(elemId);
    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;

    function run() {
      var now = new Date().getTime();
      var remaining = Math.max((endTime - now) / duration, 0);
      var value = Math.round(end - (remaining * range));
      obj.innerHTML = value;
      if (value === end) {
        clearInterval(timer);
      }
    }

    timer = setInterval(run, stepTime);
    run();
  }
};

export default utils;
