// Initialize the counter code when DOM is ready

// Scripts
// Scripts
import "./scripts/script";

// Styles
import "./styles/style.sass";

// Images
require.context("./images", true);
